import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    addToSaved,
    addToWatched,
    changeStatusWatched,
    getSingleSaved,
    getSingleWatched,
    removeFromSaved,
} from '../../api/user-contents-requests';
import { ROUTES } from '../../resources/routes-constants';
import { Filter, FilterParams, Movie, TvShow } from '../../models/data';
import { Reducers, ReducerUser } from '../../models/reducers';
import { ResultType, SavedType, WatchedType } from '../../models/user';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import ResultPageDesktop from './Desktop/ResultPageDesktop';
import { useTranslation } from 'react-i18next';
import ResultPageMobile from './Mobile/ResultPageMobile';
import { ContentType } from '../../utilities/enum/contentType';
import { getMovieDetail, getMovieFiltered, getMovieRandom, getRecommendedMovie } from '../../api/movies-requests';
import { getRecommendedTvShow, getTvShowDetail, getTvShowFiltered, getTvShowRandom } from '../../api/tv-shows-requests';
import { dispatchMoviesCountPerUser, dispatchTvShowsCountPerUser } from '../../store/actions/thunk_actions';
import Head from '../../components/Head';
import { setPageTitle } from '../../utilities/functions';

const ResultPage: React.FC = () => {
    const { t } = useTranslation();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const [content, setContent] = useState<Movie | TvShow | null>(null);
    const [reference, setReference] = useState<WatchedType | null>(null);
    const [filter, setFilter] = useState<Filter | null>(null);
    const [isFeeling, setIsFeeling] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [type, setType] = useState<ContentType | undefined>();
    const [loading, setLoading] = useState(false);
    const [loadingContent, setLoadingContent] = useState(false);
    const [isWatched, setIsWatched] = useState(false);
    const [contentWatchedId, setContentWatchedId] = useState<number | null>(null);
    const [contentSavedId, setContentSavedId] = useState<number | null>(null);
    const [isLike, setIsLike] = useState(false);
    const [isInWatchList, setIsInWatchList] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { contentId } = useParams();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [removeContent, setRemoveContent] = useState(false);
    const dispatch = useDispatch();

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');
    }, []);

    const handleCancelDelete = useCallback(() => {
        setRemoveContent(false);
    }, []);

    const checkIsWatched = useCallback(async () => {
        if (content && type && user.user) {
            setLoading(true);
            try {
                await getSingleWatched(
                    content.id,
                    type === ContentType.MOVIE ? ContentType.MOVIE : ContentType.TV_SHOW,
                    user.user.id,
                )
                    .then((response) => {
                        if ((response as WatchedType).id) {
                            setIsWatched(true);
                            setContentWatchedId((response as WatchedType).id ?? null);
                            setIsLike((response as WatchedType).isLike);
                        } else {
                            setIsWatched(false);
                        }
                    })
                    .catch((error) => {
                        throw error;
                    });
            } catch (error: any) {
                if (process.env.NODE_ENV === 'production') {
                    //console.clear();
                }
                setIsWatched(false);
            }
            setLoading(false);
        } else {
            setIsWatched(false);
        }
    }, [content, type, user.user]);

    const checkIsSaved = useCallback(async () => {
        if (content && type && user.user) {
            try {
                setLoading(true);
                await getSingleSaved(
                    content.id,
                    type === ContentType.MOVIE ? ContentType.MOVIE : ContentType.TV_SHOW,
                    user.user.id,
                )
                    .then((response) => {
                        if ((response as SavedType).id) {
                            setIsInWatchList(true);
                            setContentSavedId((response as SavedType).id ?? null);
                        } else {
                            setIsInWatchList(false);
                        }
                    })
                    .catch((error) => {
                        throw error;
                    });
            } catch (error) {
                if (process.env.NODE_ENV === 'production') {
                    //console.clear();
                }
                setIsInWatchList(false);
            }
            setLoading(false);
        } else {
            setIsWatched(false);
        }
    }, [content, type, user.user]);

    const checkIfNotExistSaved = useCallback(() => {
        if (content) {
            const nameType = type === ContentType.MOVIE ? t('errors.this_movie') : t('errors.this_tv_show');
            if (isWatched) {
                setError(`${nameType} ${t('errors.exist_in_watched')}`);
                return false;
            }
            if (isInWatchList) {
                setError(`${nameType} ${t('errors.exist_in_watchlist')}`);
                return false;
            }
            return true;
        }
    }, [content, type, t, isWatched, isInWatchList]);

    const handleSaveContent = useCallback(async () => {
        handleCloseToast();
        const notExist = checkIfNotExistSaved();
        if (user.user && content && notExist) {
            const item = {
                idUser: user.user.id,
                type: type as ContentType,
                idContent: content.id,
                title: content.name,
                img: content.poster,
            };
            setLoading(true);
            addToSaved(item)
                .then(async (data) => {
                    if ((data as SavedType).id) {
                        setIsInWatchList(true);
                        setContentSavedId((data as SavedType).id ?? null);
                        if (type === ContentType.MOVIE) {
                            setSuccess(t('result_page.common.movie_added_watchlist'));
                        }
                        if (type === ContentType.TV_SHOW) {
                            setSuccess(t('result_page.common.tv_show_added_watchlist'));
                        }
                    }
                })
                .catch((error) => setError(error.toString()))
                .finally(() => setLoading(false));
        }
    }, [handleCloseToast, checkIfNotExistSaved, user.user, content, type, t]);

    const handleWatchedContent = useCallback(
        async (status: boolean) => {
            if (user.user && content) {
                const item: WatchedType = {
                    idUser: user.user?.id,
                    type: type as ContentType,
                    idContent: content.id,
                    title: content.name,
                    img: content.poster,
                    isLike: status,
                };
                setLoading(true);
                await addToWatched(item)
                    .then(async (data) => {
                        if ((data as WatchedType).id) {
                            setContentWatchedId((data as WatchedType).id ?? null);
                            setIsWatched(true);
                            if (type === ContentType.MOVIE) {
                                await dispatch(dispatchMoviesCountPerUser(user.user!.id));
                                setSuccess(t('result_page.common.movie_added_watched'));
                            }
                            if (type === ContentType.TV_SHOW) {
                                await dispatch(dispatchTvShowsCountPerUser(user.user!.id));
                                setSuccess(t('result_page.common.tv_show_added_watched'));
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.error) {
                            setError(t(`errors.${error.error}`) ?? t('errors.adding_content_error'));
                        } else {
                            setError(t('errors.adding_content_error'));
                        }
                    });
                if (isInWatchList && contentSavedId) {
                    await removeFromSaved(contentSavedId)
                        .then(async (data) => {
                            if ((data as ResultType).status === 'ok') {
                                setIsInWatchList(false);
                                setRemoveContent(false);
                                setSuccess(t('result_page.common.content_removed_watched'));
                            }
                        })
                        .catch((error) => {
                            if (error.error) {
                                setError(t(`errors.${error.error}`) ?? t('errors.removing_content_error'));
                            } else {
                                setError(t(`errors.${error.error}`) ?? t('errors.adding_content_error'));
                            }
                        });
                }
                setLoading(false);
            }
        },
        [user.user, content, type, isInWatchList, contentSavedId, t],
    );

    const handleChangeLike = useCallback(() => {
        if (user.user && user.user.id && content) {
            if (isWatched && contentWatchedId != null) {
                setLoading(true);
                changeStatusWatched(!isLike, user.user.id, contentWatchedId)
                    .then(async (data) => {
                        if ((data as WatchedType).id) {
                            if (type === ContentType.MOVIE) {
                                setSuccess(t('result_page.common.movie_updated'));
                            }
                            if (type === ContentType.TV_SHOW) {
                                setSuccess(t('result_page.common.tv_show_updated'));
                            }
                            setIsLike(!isLike);
                        }
                    })
                    .catch((error) => {
                        if (error.error) {
                            setError(t(`errors.${error.error}`) ?? t('errors.updating_content_error'));
                        } else {
                            setError(t('errors.updating_content_error'));
                        }
                    })
                    .finally(() => setLoading(false));
            } else {
                setError(t('errors.content_not_found'));
            }
        }
    }, [user.user, content, isWatched, contentWatchedId, isLike, type, t]);

    const getContentDetail = useCallback(async (contentType: ContentType | undefined, contentId: string) => {
        setLoadingContent(true);
        try {
            const contentData =
                contentType == ContentType.MOVIE ? await getMovieDetail(+contentId) : await getTvShowDetail(+contentId);
            setContent(contentData);
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                //console.clear();
            }
        }
        setLoadingContent(false);
    }, []);

    const getSuggestedContent: (contentType: ContentType | undefined) => void = useCallback(
        async (contentType: ContentType | undefined) => {
            let contentTypeVariable = contentType;
            if (!contentType) {
                contentTypeVariable = type as ContentType;
            }
            setLoadingContent(true);
            try {
                const contentResult =
                    contentTypeVariable === ContentType.MOVIE
                        ? await getRecommendedMovie(user.user?.id ?? -1)
                        : await getRecommendedTvShow(user.user?.id ?? -1);
                setContent(contentResult.result);
                setReference(contentResult.reference);
                // firebase.analytics().logEvent("search_movie");
            } catch (error: any) {
                if (error.response && error.response.status === 404) {
                    //console.clear();
                }
            }
            setLoadingContent(false);
        },
        [user],
    );

    const getRandomContent: (contentType: ContentType | undefined) => void = useCallback(
        async (contentType: ContentType | undefined) => {
            let contentTypeVariable = contentType;
            if (!contentType) {
                contentTypeVariable = type as ContentType;
            }
            setLoadingContent(true);
            try {
                const contentData =
                    contentTypeVariable === ContentType.MOVIE ? await getMovieRandom() : await getTvShowRandom();
                setContent(contentData);
                // firebase.analytics().logEvent("search_movie");
            } catch (error: any) {
                if (error.response && error.response.status === 404) {
                    //console.clear();
                }
            }
            setLoadingContent(false);
        },
        [type],
    );

    const getFilteredContent: () => void = useCallback(async () => {
        if (filter && type) {
            setLoadingContent(true);
            try {
                let filterParams: FilterParams = {};
                if (filter.genres.length > 0) {
                    filterParams.genres = filter.genres.map((genre) => genre.id).join('|');
                }
                if (filter.providers.length > 0) {
                    filterParams.providers = filter.providers.map((provider) => provider.id).join('|');
                }
                if (filter.startDate) {
                    filterParams.startDate = filter.startDate;
                }
                if (filter.endDate) {
                    filterParams.endDate = filter.endDate;
                }
                const filteredContent =
                    type === ContentType.MOVIE
                        ? await getMovieFiltered(filterParams)
                        : await getTvShowFiltered(filterParams);
                setContent(filteredContent);
            } catch (error: any) {
                if (error.response && error.response.status === 404) {
                    //console.clear();
                }
            }
            setLoadingContent(false);
        }
    }, [filter, type]);

    const handleRemoveContent = useCallback(async () => {
        if (content && user.user) {
            if (isInWatchList && contentSavedId) {
                setLoading(true);
                removeFromSaved(contentSavedId)
                    .then(async (data) => {
                        if ((data as ResultType).status === 'ok') {
                            setIsInWatchList(false);
                            setRemoveContent(false);
                            setSuccess(t('result_page.common.content_removed_watchlist'));
                        }
                    })
                    .catch((error) => {
                        if (error.error) {
                            setError(t(`errors.${error.error}`) ?? t('errors.removing_content_error'));
                        } else {
                            setError(t('errors.removing_content_error'));
                        }
                    })
                    .finally(() => setLoading(false));
            }
        }
    }, [content, user.user, isInWatchList, contentSavedId, t]);

    const handleOnOtherContentClick = useCallback(
        (selectedType: ContentType) => {
            if (selectedType !== type) {
                if (!user.user || !user.user.settings.randomSearchWithPreferences) {
                    setFilter(null);
                }
                setType(selectedType);
                return;
            }
            startGettingContent(selectedType);
        },
        [filter, getFilteredContent, getRandomContent, type, user.user],
    );

    const startGettingContent = useCallback(
        (selectedType: ContentType) => {
            if (contentId) {
                getContentDetail(selectedType, contentId);
                setReference(null);
                return;
            }
            const isSuggested = Math.random() < 0.5;
            const hasResults =
                selectedType == ContentType.MOVIE ? user.watchedMoviesCount > 0 : user.watchedTvShowsCount > 0;
            if (user.user && isSuggested && hasResults && !isFeeling && !isFilter) {
                getSuggestedContent(selectedType);
            } else {
                setReference(null);
                if (filter) {
                    getFilteredContent();
                } else {
                    getRandomContent(selectedType);
                }
            }
        },
        [user.user, type, filter, contentId, isFeeling, isFilter],
    );

    useEffect(() => {
        if (!location || !location.state || !location.state.type) {
            navigate(ROUTES.HOME_PAGE);
            return;
        }
        if (location.state.filter) {
            setFilter(location.state.filter);
        } else {
            setFilter(null);
        }
        if (location.state.isFeeling) {
            setIsFeeling(location.state.isFeeling);
        }
        if (location.state.isFilter) {
            setIsFeeling(location.state.isFilter);
        }
        if (location.state.type) {
            setType(location.state.type);
        }
    }, [location, location.pathname, navigate]);

    useEffect(() => {
        if (type) {
            startGettingContent(type);
        }
    }, [contentId, filter, getContentDetail, getFilteredContent, getRandomContent, type]);

    useEffect(() => {
        checkIsWatched();
        checkIsSaved();
    }, [user, content, checkIsWatched, checkIsSaved]);

    useEffect(() => {
        if (loadingContent) {
            setPageTitle('Sto cercando il risultato migliore per te');
        }
        if (content && !loadingContent) {
            setPageTitle(content.name);
        }
        if (!content && !loadingContent) {
            setPageTitle('Nessun risultato trovato');
        }
    }, [content, loadingContent]);

    return (
        <>
            <Head path={location.pathname} isIndexable={false} />
            <ViewportSwitchComponent
                desktopComponent={
                    <ResultPageDesktop
                        content={content}
                        reference={reference}
                        type={type as ContentType}
                        removeContent={removeContent}
                        isWatched={isWatched}
                        isInWatchList={isInWatchList}
                        isLike={isLike}
                        user={user.user}
                        loadingContent={loadingContent}
                        success={success}
                        error={error}
                        loading={loading}
                        onRemoveContent={handleRemoveContent}
                        onCancelDelete={handleCancelDelete}
                        onConfirmDelete={handleRemoveContent}
                        onOtherContentClick={handleOnOtherContentClick}
                        onSaveContent={handleSaveContent}
                        onAddToWatched={handleWatchedContent}
                        onChangeLike={handleChangeLike}
                        onCloseToast={handleCloseToast}
                    />
                }
                mobileComponent={
                    <ResultPageMobile
                        content={content}
                        reference={reference}
                        type={type as ContentType}
                        removeContent={removeContent}
                        isWatched={isWatched}
                        isInWatchList={isInWatchList}
                        isLike={isLike}
                        user={user.user}
                        loadingContent={loadingContent}
                        success={success}
                        error={error}
                        loading={loading}
                        onRemoveContent={handleRemoveContent}
                        onCancelDelete={handleCancelDelete}
                        onConfirmDelete={handleRemoveContent}
                        onOtherContentClick={handleOnOtherContentClick}
                        onSaveContent={handleSaveContent}
                        onAddToWatched={handleWatchedContent}
                        onChangeLike={handleChangeLike}
                        onCloseToast={handleCloseToast}
                    />
                }
            />
        </>
    );
};

export default ResultPage;
