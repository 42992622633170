import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
    path: string;
    isIndexable?: boolean;
}

const Head: FC<Props> = ({ path, isIndexable = true }) => {
    const MAIN_DOMAIN = 'https://whatwatch.tv';

    const metaIndex = useMemo(() => {
        if (isIndexable) {
            return <meta name="robots" content="index, follow"></meta>;
        }
        return <meta name="robots" content="noindex, nofollow"></meta>;
    }, [isIndexable]);

    return (
        <Helmet>
            <link rel="canonical" href={`${MAIN_DOMAIN}${path}`} hrefLang="x-default" />
            {metaIndex}
        </Helmet>
    );
};

export default Head;
