import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LOGO from '../../../resources/images/whatwatch-logo.svg';
import { ROUTES } from '../../../resources/routes-constants';
import { ReducerUser, Reducers } from '../../../models/reducers';
import useWindowDimensions from '../../../utilities/hooks';
import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import Title from '../../../components/Title';
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../../utilities/enum/contentType';

import './HeaderDesktop.scss';
import { setPageTitle } from '../../../utilities/functions';

interface Props {
    isInDetail: boolean;
    hideLogo?: boolean;
    type?: ContentType | string;
    isAccountPage?: boolean;
    isResetPasswordPage?: boolean;
    hideOtherSearchButtons?: boolean;
    otherFilmClick?: () => void;
    otherShowClick?: () => void;
    closeAccount?: () => void;
    tabs?: JSX.Element[];
}

const HeaderDesktop: React.FC<Props> = ({
    isInDetail,
    hideLogo = false,
    type = '',
    isAccountPage = false,
    isResetPasswordPage = false,
    hideOtherSearchButtons = false,
    otherFilmClick = () => {
        return;
    },
    otherShowClick = () => {
        return;
    },
    closeAccount = () => {
        return;
    },
    tabs = [],
}) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const navigate = useNavigate();

    if (isResetPasswordPage) {
        return (
            <div className="header">
                <div style={{ position: 'absolute', left: 20, top: 20 }}>
                    <img
                        src={LOGO}
                        alt="logo WhatWatch"
                        style={{ maxWidth: width * 0.025, cursor: 'pointer' }}
                        onClick={() => {
                            setPageTitle('Cosa guarderai stasera?', true);
                            navigate(ROUTES.HOME_PAGE);
                        }}
                    />
                </div>
                <Title text={t('header.reset_password')} flex={1} />
            </div>
        );
    }

    if (isAccountPage) {
        return (
            <div className="header -account">
                <div style={{ position: 'absolute', left: 20, top: 20 }}>
                    <ButtonIcon
                        icon="close"
                        size={32}
                        onClick={closeAccount}
                        style={{ background: 'transparent', fontSize: 32, width: 32, height: 32 }}
                    />
                </div>
                <Title text={t('header.account')} flex={1} />
                {tabs.length > 0 ? <div className="header-tabSelector">{tabs}</div> : null}
            </div>
        );
    }

    return (
        <div className="header">
            <ButtonIcon
                icon="user"
                size={44}
                onClick={() => {
                    if (user.user) {
                        setPageTitle('Il tuo account');
                        navigate(ROUTES.ACCOUNT_PAGE);
                    } else {
                        setPageTitle('Accedi ora per sbloccare maggiori funzionalità');
                        navigate(ROUTES.LOGIN_PAGE);
                    }
                }}
                style={{
                    position: 'absolute',
                    left: 20,
                    top: 20,
                    background: 'transparent',
                    fontSize: 44,
                    width: 44,
                    height: 44,
                }}
            />
            {!hideLogo ? (
                <img
                    src={LOGO}
                    alt="logo WhatWatch"
                    className="header-logo"
                    onClick={() => {
                        setPageTitle('Cosa guarderai stasera?', true);
                        navigate(ROUTES.HOME_PAGE);
                    }}
                />
            ) : null}
            <div className="header-suggest">
                {isInDetail && !hideOtherSearchButtons ? (
                    <div className="header-suggest_detailActions">
                        <p
                            style={{
                                opacity: type && type === ContentType.MOVIE ? 1 : 0.6,
                            }}
                            onClick={() => {
                                if (otherFilmClick) {
                                    otherFilmClick();
                                }
                            }}
                        >
                            {type && type === ContentType.MOVIE
                                ? t('header.search_another_movie')
                                : t('header.search_a_movie')}
                        </p>
                        <p
                            style={{
                                opacity: type && type === ContentType.TV_SHOW ? 1 : 0.6,
                            }}
                            onClick={() => {
                                if (otherShowClick) {
                                    otherShowClick();
                                }
                            }}
                        >
                            {type && type === ContentType.TV_SHOW
                                ? t('header.search_another_show')
                                : t('header.search_a_show')}
                        </p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default HeaderDesktop;
