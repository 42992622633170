import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    dispatchFeelings,
    dispatchMovieGenres,
    dispatchMovieProviders,
    dispatchMoviesCountPerUser,
    dispatchPopularBackdrops,
    dispatchShowGenres,
    dispatchShowProviders,
    dispatchTvShowsCountPerUser,
} from './store/actions/thunk_actions';
import { Reducers, ReducerUser } from './models/reducers';
import { verifyUser } from './api/users-requests';
import axios from 'axios';
import { logoutUser, setUser } from './store/actions/user';
import { User } from './models/user';
import AppRouter from './pages/AppRouter';
import LoadingModal from './components/Modals/LoadingModal';
import { getOnLineStatus } from './utilities/functions';
import OfflineModal from './components/Modals/OfflineModal';
import { usePrevious } from './utilities/hooks';
import InstallPwaBanner from './components/InstallPwaBanner/InstallPwaBanner';

const App: React.FC = () => {
    const dispatch = useDispatch();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const [loading, setLoading] = useState(false);
    const [isOnline, setIsOnline] = useState(getOnLineStatus());
    const previousOnlineStatus = usePrevious(isOnline);

    const initData = useCallback(async () => {
        await dispatch(dispatchPopularBackdrops());
        //await dispatch(dispatchPopularPosters());
        await dispatch(dispatchMovieGenres());
        await dispatch(dispatchShowGenres());
        await dispatch(dispatchMovieProviders());
        await dispatch(dispatchShowProviders());
        await dispatch(dispatchFeelings());
        //await dispatch(dispatchLatestIdMovie());
        //await dispatch(dispatchLatestIdShow());
    }, [dispatch]);

    const initUserData = useCallback(async () => {
        setLoading(true);
        try {
            if (user.user?.id) {
                await verifyUser(user.user.id).then((response) => {
                    if ((response as User).id) {
                        const user = response as User;
                        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
                        dispatch(setUser(user));
                        dispatch(dispatchMoviesCountPerUser(user.id));
                        dispatch(dispatchTvShowsCountPerUser(user.id));
                    } else {
                        dispatch(logoutUser());
                        delete axios.defaults.headers.common['Authorization'];
                    }
                });
            }
        } catch (error) {
            dispatch(logoutUser());
            delete axios.defaults.headers.common['Authorization'];
        }
        setLoading(false);
    }, [dispatch, user.user?.id]);

    const setOnline = useCallback(() => setIsOnline(true), []);

    const setOffline = useCallback(() => setIsOnline(false), []);

    useEffect(() => {
        if (isOnline) {
            initData();
            initUserData();
        }
    }, [initUserData, initData, isOnline]);

    useEffect(() => {
        if (previousOnlineStatus !== undefined && previousOnlineStatus === false) {
            window.location.reload();
        }
    }, [previousOnlineStatus]);

    useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, [setOffline, setOnline]);

    if (loading) {
        return <LoadingModal isVisible />;
    }

    return (
        <>
            <OfflineModal isVisible={!isOnline} />
            <InstallPwaBanner />
            <AppRouter />
        </>
    );
};

export default App;
