import { FC, useEffect, useMemo, useRef, useState } from 'react';
import './GridPostersBackground.scss';
import { useSelector } from 'react-redux';
import { ReducerData, Reducers } from '../../models/reducers';

const GridPostersBackground: FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [loadedImages, setLoadedImages] = useState<string[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const imgSrc = entry.target.getAttribute('data-src');
                    if (imgSrc) {
                        setLoadedImages((prev) => [...prev, imgSrc]);
                        (entry.target as HTMLImageElement).src = imgSrc;
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 },
        );

        const imagesToLoad = containerRef.current?.querySelectorAll('img[data-src]');
        imagesToLoad?.forEach((img) => observer.observe(img));

        return () => observer.disconnect();
    }, [data.popularPosters, containerRef]);

    const currentBgUrl = useMemo(() => {
        //const dateFormatted = format(Date.now(), 'yyyy-MM-dd');
        return `https://media.whatwatch.tv/posters-grid/posters-grid-image.jpg`;
    }, []);

    return (
        <div className="gridCoversBackground" style={{ backgroundImage: `url(${currentBgUrl})` }}>
            {/* <div className="gridCoversBackground_grid">
                {loadedImages.map((img, index) => {
                    return (
                        <div className="gridCoversBackground_gridItem" key={`img-${index}`}>
                            <img src={img} />
                        </div>
                    );
                })}
            </div>
            <div ref={containerRef} style={{ display: 'none' }}>
                {data.popularPosters.map((image, index) => (
                    <img key={index} data-src={image} alt={`Lazy Loaded ${index}`} />
                ))}
            </div> */}
        </div>
    );
};

export default GridPostersBackground;
