import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { initialFilter } from '../FilterPage/FilterPage';
import { Filter, Genre, Provider } from '../../models/data';
import { sendAnonymData } from '../../api/db-requests';
import { ReducerData, ReducerUser, Reducers } from '../../models/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { setAskPreference, setUser } from '../../store/actions/user';
import { editUserSettings } from '../../api/users-requests';
import { SettingsUser, SettingsUserComplete } from '../../models/user';
import { getUserGenresArray, getUserProvidersArray, setPageTitle } from '../../utilities/functions';
import { useTranslation } from 'react-i18next';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import HomePageDesktop from './Desktop/HomePageDesktop';
import HomePageMobile from './Mobile/HomePageMobile';
import { ContentType } from '../../utilities/enum/contentType';
import { postFBViewContentEvent } from '../../api/analytics-requests';
import { isMobile } from 'react-device-detect';
import Head from '../../components/Head';

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const dispatch = useDispatch();
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const location = useLocation();

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');

        setPageTitle('Cosa guarderai stasera?', true);
    }, []);

    const handleOnFeelings = useCallback(
        async (genres: Genre[], feelingId: number, selectedType: ContentType) => {
            if (selectedType) {
                let filter: Filter = { ...initialFilter, genres: genres };
                if (user.user && user.user.settings.randomSearchWithPreferences) {
                    const providers: Provider[] = getUserProvidersArray(
                        user.user.platforms,
                        data.movieProvider,
                        data.showProvider,
                    );
                    filter = { ...filter, providers };
                }
                if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
                    sendAnonymData(filter, selectedType, feelingId);
                    postFBViewContentEvent(selectedType, 'feeling', user.user != null, user.user?.email || null);
                }
                navigate(ROUTES.RESULT_PAGE, {
                    state: {
                        type: selectedType,
                        filter: filter,
                        isFeeling: true,
                    },
                });
            }
        },
        [user.user, navigate, data.movieProvider, data.showProvider],
    );

    const handleOnStandard = useCallback(
        async (selectedType: ContentType, searchSetting?: boolean) => {
            if (selectedType) {
                let filter: Filter = { ...initialFilter };
                if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
                    sendAnonymData({ ...filter }, selectedType);
                    postFBViewContentEvent(selectedType, 'random', user.user != null, user.user?.email || null);
                }
                const searchWithPreference =
                    searchSetting != undefined
                        ? searchSetting
                        : user.user
                        ? user.user.settings.randomSearchWithPreferences
                        : false;
                if (user.user && searchWithPreference) {
                    const providers: Provider[] = getUserProvidersArray(
                        user.user.platforms,
                        data.movieProvider,
                        data.showProvider,
                    );
                    const genres: Genre[] = getUserGenresArray(user.user.genres, data.movieGenres, data.showGenres);
                    filter = { ...filter, providers, genres };
                    navigate(ROUTES.RESULT_PAGE, {
                        state: {
                            type: selectedType,
                            filter: filter,
                        },
                    });
                } else {
                    navigate(ROUTES.RESULT_PAGE, {
                        state: {
                            type: selectedType,
                        },
                    });
                }
            }
        },
        [user.user, data.movieProvider, data.showProvider, data.movieGenres, data.showGenres, navigate],
    );

    const handleUpdatePreference = useCallback(
        (status: boolean, selectedType: ContentType) => {
            if (user.user && user.user.id) {
                setLoading(true);
                const editSettingsObject: SettingsUser = {
                    randomSearchWithPreferences: status,
                };
                editUserSettings(user.user.settings.id, editSettingsObject)
                    .then((response) => {
                        if ((response as SettingsUserComplete).id && user.user) {
                            dispatch(
                                setUser({
                                    ...user.user,
                                    settings: response as SettingsUserComplete,
                                }),
                            );
                            dispatch(setAskPreference(false));
                            if (isMobile) {
                                handleOnStandard(selectedType, status);
                            }
                            setSuccess(t('home_page.desktop.preferences_saved'));
                        } else {
                            setError(t('home_page.desktop.error_during_saved_preferences'));
                        }
                    })
                    .catch((error) => {
                        if (error.error) {
                            setError(error.error);
                        } else {
                            setError(error.toString());
                        }
                    })
                    .finally(() => setLoading(false));
            }
        },
        [user.user, dispatch, t],
    );

    return (
        <>
            <Head path={location.pathname} />
            <ViewportSwitchComponent
                desktopComponent={
                    <HomePageDesktop
                        user={user.user}
                        askPreference={user.askPreference}
                        success={success}
                        error={error}
                        loading={loading}
                        onFeelings={handleOnFeelings}
                        onStandard={handleOnStandard}
                        onUpdatePreference={handleUpdatePreference}
                        onCloseToast={handleCloseToast}
                    />
                }
                mobileComponent={
                    <HomePageMobile
                        user={user.user}
                        askPreference={user.askPreference}
                        success={success}
                        error={error}
                        loading={loading}
                        onStandard={handleOnStandard}
                        onUpdatePreference={handleUpdatePreference}
                        onCloseToast={handleCloseToast}
                    />
                }
            />
        </>
    );
};

export default HomePage;
