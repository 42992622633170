import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/Button/Button';
import Paragraph from '../../../components/Paragraph';
import Title from '../../../components/Title';
import { SocialLoginData, checkEmailExistance, confirmAccount, socialLogin } from '../../../api/users-requests';
import ErrorBox from '../../../components/ErrorBox';
import LoadingModal from '../../../components/Modals/LoadingModal';
import { parse } from 'qs';
import Toast from '../../../components/Toast';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/actions/user';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import { SocialLoginPlatform } from '../../../utilities/enum/socialLogin';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { User } from '../../../models/user';
import { useTranslation } from 'react-i18next';
import ConfirmSocialRegistrationModal from '../../../components/Modals/ConfirmSocialRegistration';
import { facebookAppId } from '../../../resources/constants';
import { ButtonColors } from '../../../utilities/enum/buttonColors';
import LoginForm from '../../../components/Forms/LoginForm';

export interface LoginData {
    email: string;
    password: string;
}

interface Props {
    onRegister: () => void;
    onResetPassword: () => void;
}

const initialLoginData = {
    email: '',
    password: '',
};

const LoginPhase: React.FC<Props> = ({ onRegister, onResetPassword }) => {
    const { t } = useTranslation();
    const [loginData, setLoginData] = useState<LoginData>(initialLoginData);
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmCompleted, setConfirmCompleted] = useState<boolean | null>(null);
    const [socialLoginData, setSocialLoginData] = useState<SocialLoginData | null>(null);
    const [showConfirmRegistration, setShowConfirmRegistration] = useState(false);

    const handleConfirmAccount = useCallback(async (token: string) => {
        if (token) {
            setLoading(true);
            await confirmAccount(token)
                .then((data) => {
                    if ((data as User).id) {
                        setConfirmCompleted(true);
                    } else {
                        setConfirmCompleted(false);
                    }
                })
                .catch(() => setConfirmCompleted(false))
                .finally(() => setLoading(false));
        }
    }, []);

    const handleCloseToast = useCallback(() => {
        setConfirmCompleted(null);
    }, []);

    const handleSocialLogin = useCallback(
        (socialLoginDataPassed: SocialLoginData | null) => {
            if (socialLoginDataPassed) {
                setLoading(true);
                socialLogin(socialLoginDataPassed)
                    .then((user) => {
                        if (user as User) {
                            const userData = user as User;
                            axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
                            dispatch(setUser(userData));
                        }
                    })
                    .catch((error) => {
                        setError(t(`errors.${error.error}`) ?? t('errors.generic_login_error'));
                    })
                    .finally(() => setLoading(false));
            }
        },
        [dispatch, t],
    );

    const handleCancelSocialLogin = useCallback(() => {
        setShowConfirmRegistration(false);
        setSocialLoginData(null);
    }, []);

    const handleFacebookLogin = useCallback(
        async (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
            setLoading(true);
            if (!(response as any).status) {
                const loginInfo = response as ReactFacebookLoginInfo;
                const objectToPush = {
                    email: loginInfo.email ?? '',
                    name: loginInfo.name ?? '',
                    platform: SocialLoginPlatform.FACEBOOK,
                };
                if (loginInfo.email) {
                    try {
                        setSocialLoginData(objectToPush);
                        const foundEmail = await checkEmailExistance(objectToPush.email);
                        if (foundEmail) {
                            handleSocialLogin(objectToPush);
                        } else {
                            setShowConfirmRegistration(true);
                            setLoading(false);
                        }
                    } catch (error: any) {
                        setError(t(`errors.${error.error}`) ?? t('errors.generic_login_error'));
                    }
                } else {
                    setError(t('errors.generic_login_error'));
                    setLoading(false);
                }
            } else {
                setError(t('errors.generic_login_error'));
                setLoading(false);
            }
        },
        [handleSocialLogin, t],
    );

    const handleGoogleLogin = useCallback(
        (response: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
            setLoading(true);
            if (response.access_token) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${response.access_token}`,
                            Accept: 'application/json',
                        },
                    })
                    .then(async (res) => {
                        if (res.data) {
                            const objectToPush = {
                                email: res.data.email ?? '',
                                name: res.data.name ?? '',
                                platform: SocialLoginPlatform.GOOGLE,
                            };
                            setSocialLoginData(objectToPush);
                            const foundEmail = await checkEmailExistance(objectToPush.email);
                            if (foundEmail) {
                                handleSocialLogin(objectToPush);
                            } else {
                                setShowConfirmRegistration(true);
                                setLoading(false);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setError(t('errors.generic_login_error'));
                    });
            } else {
                setError(t('errors.generic_login_error'));
            }
            setLoading(false);
        },

        [handleSocialLogin, t],
    );

    const toastMessage = useMemo(() => {
        if (confirmCompleted === true) {
            return t('login_page.account_confirmed');
        }
        if (confirmCompleted === false) {
            return t('errors.generic_confirm_account_error');
        }
        return '';
    }, [confirmCompleted, t]);

    useEffect(() => {
        const params = parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        if (params && params.token) {
            handleConfirmAccount(params.token as string);
        }
    }, [handleConfirmAccount]);

    const googleLogin = useGoogleLogin({
        onSuccess: handleGoogleLogin,
        onError: () => {
            setError(t('errors.generic_login_error'));
            setLoading(false);
        },
    });

    return (
        <div className="loginContainer-content_formColumn__columnContent">
            <Title text={t('login_page.signin_title')} align="left" weight="bold" />
            <LoginForm />
            <div className="loginContainer-content_formColumn__columnContent-socialLoginContainer">
                <FacebookLogin
                    appId={facebookAppId}
                    callback={handleFacebookLogin}
                    fields="name, email"
                    render={(renderProps) => {
                        return (
                            <Button
                                styleClass={ButtonColors.WHITE_TRANSPARENT}
                                text={t('login_page.facebook')}
                                onClick={renderProps.onClick}
                                icon="facebook"
                            />
                        );
                    }}
                    disableMobileRedirect
                />
                <Button
                    styleClass={ButtonColors.WHITE_TRANSPARENT}
                    text={t('login_page.google')}
                    onClick={googleLogin}
                    icon="google"
                />
            </div>
            {error ? <ErrorBox message={error} /> : null}
            <Paragraph>
                <Button
                    text={t('login_page.forgot_password')}
                    onClick={onResetPassword}
                    styleClass={ButtonColors.LINK_WHITE}
                    style={{ opacity: 1 }}
                />
            </Paragraph>
            <Title text={t('login_page.not_an_account')} align="left" weight="bold" />
            <Button
                styleClass={ButtonColors.WHITE_TRANSPARENT}
                text={t('login_page.signup')}
                onClick={() => {
                    setLoginData(initialLoginData);
                    onRegister();
                }}
            />
            <Toast message={toastMessage} onClose={handleCloseToast} type={confirmCompleted ? 'success' : 'error'} />
            <LoadingModal isVisible={loading} />
            <ConfirmSocialRegistrationModal
                isVisible={showConfirmRegistration}
                onConfirm={() => {
                    handleSocialLogin(socialLoginData);
                }}
                onCancel={handleCancelSocialLogin}
            />
        </div>
    );
};

export default LoginPhase;
