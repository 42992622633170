import { FC, useCallback, useEffect } from 'react';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import AccountPageDesktop from './Desktop/AccountPageDesktop';
import AccountPageMobile from './Mobile/AccountPageMobile';
import { ReducerUser, Reducers } from '../../models/reducers';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/actions/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import Head from '../../components/Head';
import { setPageTitle } from '../../utilities/functions';

const AccountPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const location = useLocation();

    const handleLogout = useCallback(() => {
        delete axios.defaults.headers.common['Authorization'];
        dispatch(logoutUser());
        navigate(ROUTES.LOGIN_PAGE);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (!user.user) {
            handleLogout();
        }
    }, [handleLogout, user]);

    useEffect(() => {
        setPageTitle('Il tuo account');
    }, []);

    return (
        <>
            <Head path={location.pathname} isIndexable={false} />
            <ViewportSwitchComponent
                desktopComponent={<AccountPageDesktop user={user.user} onLogout={handleLogout} />}
                mobileComponent={<AccountPageMobile user={user.user} onLogout={handleLogout} />}
            />
        </>
    );
};

export default AccountPage;
