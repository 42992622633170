import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '../../../models/user';

import './AccountPageMobile.scss';
import AccountOverView from './Views/AccountOverView';
import { AccountView } from '../../../utilities/enum/accountView';
import PlatformsView from './Views/PlatformsView';
import GenresView from './Views/GenresView';
import ContetsListView from './Views/ContentsListView';
import SettingsView from './Views/SettingsView';
import Header from '../../../components/Header/Header';
import { ROUTES } from '../../../resources/routes-constants';
import { ContentType } from '../../../utilities/enum/contentType';
import FixedBackground from '../../../components/FixedBackground/FixedBackground';
import { FixedBackgroundColors } from '../../../utilities/enum/fixedBackgroundColors';
import SuggestionModal from '../../../components/Modals/SuggestionsModal';
import { setPageTitle } from '../../../utilities/functions';

interface Props {
    user: User | null;
    onLogout: () => void;
}

const AccountPageMobile: FC<Props> = ({ user, onLogout }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [currentView, setCurrentView] = useState(AccountView.OVERVIEW);
    const [currentType, setCurrentType] = useState<ContentType | null>(null);

    const handleChangeView = useCallback((view: AccountView) => {
        setCurrentView(view);
    }, []);

    const renderCurrentView = useMemo(() => {
        switch (currentView) {
            case AccountView.PLATFORMS:
                return <PlatformsView user={user} onChangeType={setCurrentType} />;
            case AccountView.GENRES:
                return <GenresView user={user} onChangeType={setCurrentType} />;
            case AccountView.WATCHED:
                return <ContetsListView type="watched" onChangeType={setCurrentType} />;
            case AccountView.SAVED:
                return <ContetsListView type="saved" onChangeType={setCurrentType} />;
            case AccountView.SETTINGS:
                setCurrentType(null);
                return <SettingsView user={user} />;
            default:
                return <AccountOverView user={user} onLogout={onLogout} onChangeView={handleChangeView} />;
        }
    }, [currentView, handleChangeView, onLogout, user]);

    return (
        <div className={`accountMobileContainer`}>
            <FixedBackground
                color={
                    currentType
                        ? currentType == ContentType.MOVIE
                            ? FixedBackgroundColors.MOVIE_GRADIENT
                            : FixedBackgroundColors.TV_SHOW_GRADIENT
                        : FixedBackgroundColors.DARK_GRADIENT
                }
            />
            <Header
                isAccountPage
                isInDetail={false}
                closeAccount={() => {
                    if (currentView === AccountView.OVERVIEW) {
                        if (location.key !== 'default') {
                            navigate(-1);
                        } else {
                            setPageTitle('Cosa guarderai stasera?', true);
                            navigate(ROUTES.HOME_PAGE);
                        }
                    } else {
                        setCurrentType(null);
                        setCurrentView(AccountView.OVERVIEW);
                    }
                }}
            />
            {renderCurrentView}
            <SuggestionModal isVisible={user?.isFirstLogin ?? false} user={user} />
        </div>
    );
};

export default AccountPageMobile;
