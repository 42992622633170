import { FC, useMemo, useState } from 'react';
import Header from '../../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../resources/routes-constants';
import { UserTab, userTabsArray } from '../../../utilities/enum/usertab';
import Paragraph from '../../../components/Paragraph';
import SearchPreferencesTab from './Tabs/SearchPreferencesTab';
import SettingsTab from './Tabs/SettingsTab';
import ContentListTab from './Tabs/ContentListTab';
import { useTranslation } from 'react-i18next';
import { User } from '../../../models/user';

import './AccountPageDesktop.scss';
import Button from '../../../components/Button/Button';
import { ButtonColors } from '../../../utilities/enum/buttonColors';
import SuggestionModal from '../../../components/Modals/SuggestionsModal';
import { setPageTitle } from '../../../utilities/functions';

interface Props {
    user: User | null;
    onLogout: () => void;
}

const AccountPageDesktop: FC<Props> = ({ user, onLogout }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<UserTab>(UserTab.SEARCH_PREFERENCES);
    const navigate = useNavigate();

    const tabsRendered = useMemo(() => {
        const array = userTabsArray.map((tab, index) => {
            return (
                <Button
                    styleClass={index == selectedTab ? ButtonColors.LINK_WHITE : ButtonColors.LINK_OPACITY}
                    text={t(`account_page.desktop.menu.${tab}`)}
                    onClick={() => {
                        setSelectedTab(index);
                    }}
                    key={index}
                    style={{ width: 'auto' }}
                />
                // <Paragraph key={tab}>
                //     <p
                //         onClick={() => setSelectedTab(index)}
                //         style={{ color: '#fff', cursor: 'pointer', opacity: index === selectedTab ? 1 : 0.6 }}
                //     >
                //         {t(`account_page.desktop.menu.${tab}`)}
                //     </p>
                // </Paragraph>
            );
        });
        array.push(
            <Button
                styleClass={ButtonColors.LINK_DELETE}
                text={t('account_page.desktop.menu.signout')}
                onClick={onLogout}
                key={'logout'}
                style={{ width: 'auto' }}
            />,
        );
        return array;
    }, [onLogout, selectedTab, t]);

    const selectedTabRender = useMemo(() => {
        switch (selectedTab) {
            case UserTab.SEARCH_PREFERENCES:
                return <SearchPreferencesTab />;
            case UserTab.WATCHLIST:
                return <ContentListTab contentType="saved" />;
            case UserTab.WATCHED:
                return <ContentListTab contentType="watched" />;
            case UserTab.SETTINGS:
                return <SettingsTab />;
        }
    }, [selectedTab]);

    return (
        <div className={`accountContainer`}>
            <Header
                isAccountPage
                isInDetail={false}
                closeAccount={() => {
                    setPageTitle('Cosa guarderai stasera?', true);
                    navigate(ROUTES.HOME_PAGE);
                }}
                tabs={tabsRendered}
            />
            <div className="accountContainer-tabRendered">{selectedTabRender}</div>
            <SuggestionModal isVisible={user?.isFirstLogin ?? false} user={user} />
        </div>
    );
};

export default AccountPageDesktop;
