import Button from '../../components/Button/Button';
import { ROUTES } from '../../resources/routes-constants';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './NotFoundPage.scss';
import Header from '../../components/Header/Header';
import { ButtonColors } from '../../utilities/enum/buttonColors';
import { setPageTitle } from '../../utilities/functions';

const NotFoundPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleGoHome = useCallback(() => {
        navigate(ROUTES.HOME_PAGE);
    }, [navigate]);

    useEffect(() => {
        setPageTitle('Pagina non trovata');
    }, []);

    return (
        <div className="notFoundPage">
            <Header isInDetail={false} />
            <h1>{t('not_found_page.title')}</h1>
            <h2>{t('not_found_page.subtitle')}</h2>
            <Button
                styleClass={ButtonColors.WHITE_TRANSPARENT}
                style={{ width: 'auto' }}
                text={t('not_found_page.button')}
                onClick={handleGoHome}
            />
        </div>
    );
};

export default NotFoundPage;
