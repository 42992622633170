import { FC, useMemo } from 'react';
import Modal from '@mui/material/Modal';
import { COLORS } from '../../resources/constants';
import { useSelector } from 'react-redux';
import { ReducerData, Reducers } from '../../models/reducers';
import FeelingItem from '../../components/FeelingItem/FeelingItem';
import { Genre } from '../../models/data';
import { ContentType } from '../../utilities/enum/contentType';

import './Modals.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    isVisible: boolean;
    type: ContentType;
    onClose: () => void;
    onFeelings: (genres: Genre[], feelingId: number) => void;
    onStandard: () => void;
}

const SelectSearchTypeModal: FC<Props> = ({ isVisible, type, onClose, onFeelings, onStandard }) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const { t } = useTranslation();

    const feelingsModalImage = useMemo(() => {
        return 'https://media.whatwatch.tv/feelings/v2/basic-eye.svg';
    }, []);

    return (
        <Modal
            open={isVisible}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox selectFeelingsModal">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <img src={`${feelingsModalImage}`} style={{ maxWidth: '6vw', marginBottom: 20 }} alt={'feeling'} />
                    <h2 className="modalBox-title">{t('feeling_page.mobile.title')}</h2>
                    <p className="modalBox-subtitle">{t('feeling_page.mobile.subtitle')}</p>
                </div>
                <div className="selectFeelingsModal-feelingsGrid">
                    {data.feelings.map((f) => {
                        return (
                            <FeelingItem
                                key={`feeling${f.id}`}
                                feeling={f}
                                onClick={() => {
                                    const genres =
                                        type === ContentType.MOVIE
                                            ? data.movieGenres.filter((g) => f.movieGenres.includes(g.id))
                                            : data.showGenres.filter((g) => f.tvGenres.includes(g.id));
                                    onFeelings(genres, f.id);
                                }}
                            />
                        );
                    })}
                </div>
                <p className="modalBox-subtitle">... oppure ...</p>
                <p
                    style={{
                        fontSize: 18,
                        color: type === ContentType.MOVIE ? COLORS.purple : COLORS.yellow,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        margin: 0,
                    }}
                    onClick={onStandard}
                >
                    Cerca {type === ContentType.MOVIE ? 'un film' : 'una serie TV'} a caso
                </p>
            </div>
        </Modal>
    );
};

export default SelectSearchTypeModal;
